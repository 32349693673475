<!--充值收货次数-->
<template>
  <div class="box">
    <div style="width: 41.66667%">
      <!-- 上账 -->
      <GlobalChunk v-if="type == '00'" icon="add" title="充值收货次数" :padding="[32, 0, 95, 10]">
        <el-form :inline="false" :model="formData" :rules="formRules" ref="formEle" label-width="110px">
          <el-form-item label="回收商家" prop="merchantId">
            <el-select v-model="formData.merchantId" clearable filterable placeholder="请输入或选择回收商家" style="width:100%">
              <el-option v-for="v in merchantOption" :key="v.value" :label="v.label" :value="v.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-select v-model="formData.contacts" clearable filterable placeholder="请输入或选择联系人姓名" style="width:100%">
              <el-option v-for="v in contactList" :key="v.value" :label="v.label" :value="v.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactsPhone">
            <el-input v-model="formData.contactsPhone" clearable placeholder="请输入联系电话" />
          </el-form-item>

          <el-form-item label="充值方式" prop="rechargeType">
            <el-radio-group v-model="formData.rechargeType">
              <el-radio label="01">按套餐充值</el-radio>
              <el-radio label="02">自定义充值</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.rechargeType == '01'" label="充值金额" prop="rechargeConfigId">
            <el-select v-model="formData.rechargeConfigId" clearable filterable placeholder="请选择充值金额" style="width:100%">
              <el-option v-for="v in rechargeConfigList" :key="v.value" :label="v.label" :value="v.value" />
            </el-select>
          </el-form-item>
          <template v-if="formData.rechargeType == '02'">
            <el-form-item label="充值收货次数" prop="takeDeliveryCount">
              <el-input v-model="formData.takeDeliveryCount" placeholder="请输入正整数" @blur="takeDeliveryCountBlur" @input="(val)=>formData.takeDeliveryCount = val.replace(/[^\d]/g,'').replace(/^0$/,'')">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="calcRecharge" label="充值金额" prop="takeDeliveryCount">
              <span style="color: red">{{ calcRecharge.price }}元，按{{ calcRecharge.unitPrice }}元/次折算</span>
            </el-form-item>
          </template>

          <el-form-item label="支付方式" prop="payeeWay">
            <el-select v-model="formData.payeeWay" clearable filterable placeholder="请选择收款方式" style="width:100%">
              <el-option v-for="v in payeeWayOptions" :key="v" :label="v" :value="v" />
            </el-select>
          </el-form-item>
          <el-form-item label="收款凭证" prop="voucher">
            <GlobalUploadGroup :exist-img-list="existImgList" :length="3" :fileSize="3" :isBlank="false" placeTxt="请上传本次的收款凭证(限3张内，大小不超过3M，格式为JPG或PNG)" />
          </el-form-item>
          <el-form-item label="充值备注" prop="note">
            <el-input v-model="formData.note" type="textarea" maxlength="100" rows="3" placeholder="请输入备注内容，最多100字" />
          </el-form-item>
          <el-form-item>
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary" @click="submit" :loading="isBtnLoading">确认提交</el-button>
          </el-form-item>

          <!-- <el-form-item v-for="(item, index) in formProps" :prop="item.key" :key="index"
            :label-width="item.labelWidth || '86px'" :label="item.labelName">
            
            <div v-if="item.type === 'selectFilterable' || item.type === 'select'">
              <el-select :style="{ width: item.width || '100%' }" v-model="formData[item.key]"
                :filterable="item.type !== 'select'" clearable :placeholder="item.placeholder">
                <el-option v-for="v in item.option" :key="v.value" :label="v.label" :value="v.value" />
              </el-select>
            </div>
            
            <div v-else-if="item.type === 'input'">
              <el-input :placeholder="item.placeholder" clearable v-model="formData[item.key]" />
            </div>
            
            <div v-else-if="item.key === 'voucher'">
              <GlobalUploadGroup v-bind="item.prop" :exist-img-list="existImgList" />
            </div>
            
            <div v-else-if="item.type === 'textarea'">
              <el-input show-word-limit type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"
                v-model="formData[item.key]" maxlength="100" :style="{ width: item.width }" />
            </div>
            
            <div v-else-if="item.type === 'btn'">
              <div>
                <el-button @click="goBack">返回</el-button>
                <el-button type="primary" @click="submit" :loading="isBtnLoading">确认提交</el-button>
              </div>
            </div>
          </el-form-item> -->
        </el-form>
      </GlobalChunk>
      <!-- 下账 -->
      <GlobalChunk v-if="type == '01'" icon="down" title="收货次数下账" :padding="[32, 0, 95, 10]">
        <el-form :inline="false" :model="formData2" :rules="formRules2" ref="formEle2">
          <el-form-item prop="merchantId" label-width="86px" label="回收商家">
            <el-select style="width:100%" @change="merchantIdChange" v-model="formData2.merchantId" :filterable="true"
              clearable placeholder="请输入或选择回收商家">
              <el-option v-for="v in merchantOption" :key="v.value" :label="v.label" :value="v.value" />
            </el-select>
          </el-form-item>
          <div v-if="merchantId && merchantCalc.remainScanTimes" style="padding-left: 20px;padding-bottom: 20px;">
            当前收货次数：<span style="color:#ff767f;">{{ merchantCalc.remainScanTimes }}次</span>
          </div>
          <el-form-item prop="num" label-width="86px" label="下账次数">
            <el-input placeholder="请输入下账次数" clearable v-model="formData2.num" @blur="numBlur" />
          </el-form-item>
          <!-- v-if="showFlag" -->
          <div v-if="merchantId && merchantCalc.price"
            style="padding-left: 20px;padding-bottom: 20px;color:#ff767f;font-weight: 600;">
            折算金额：{{ merchantCalc.price }}元（按1次={{ merchantCalc.unitPrice }}元换算）
          </div>
          <el-form-item prop="cause" label-width="86px" label="下账原因">
            <el-input show-word-limit type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" v-model="formData2.cause"
              maxlength="200" style=" width:1005" />
          </el-form-item>
          <!-- 提交 -->
          <div style="padding-left: 25px;">
            <!-- $router.back() -->
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary" @click="submit2" :loading="isBtnLoading">确认提交</el-button>
          </div>
        </el-form>
      </GlobalChunk>
      <!-- 动态口令 -->
      <el-dialog title="下账确认" :visible.sync="downDialogVisible" :close-on-click-modal="false" width="500px"
        @closed="downClosed">
        <div class="enable-tip">
          <div style="border-left: 4px solid #3399FF;padding-left: 10px;color:#3399FF ;">
            请仔细核对下账收货次数，折算金额需自行操作上账
          </div>
          <div style="color: #f7a86a;margin: 20px 0;">下账次数：{{ formData2.num }}次 / 折算金额：{{ merchantCalc.price || 0 }}元
          </div>
        </div>
        <div class="command">
          <div style="width: 85px;">动态口令：</div>
          <el-input v-model="downCommand" placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="downClosed">取消</el-button>
          <el-button type="primary" :loading="isBtnLoading" @click.native="downSubmit">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>

import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import { formProps, formProps2, formRules } from "@/views/payment/BillList/initState";
import GlobalUploadGroup from "@/components/global/components/elements/upload-group-element-obj.vue";
import _api from "@/utils/request";

export default {
  data() {
    const newFormRules = formRules;
    const voucherValidate = (rule, value, callback) => {
      if (!Array.isArray(this.formData.voucher) || this.formData.voucher.length === 0) {
        return callback(new Error("请上传图片"))
      }
      return callback();
    };
    newFormRules.voucher = [
      {
        validator: voucherValidate, trigger: "change"
      }
    ]
    return {
      downCommand: '',//下账动态口令
      showFlag: false,
      merchantCalc: {},
      calcRecharge: null,
      merchantId: '',
      merchantOption: [],
      payeeWayOptions: ['私卡转账', '授权调账'],
      rechargeConfigList: [], // 套餐下拉
      contactList: [], // 联系人信息
      formData2: {
        merchantId: '',
        num: null,
        cause: ''
      },//下账
      formRules2: {//下账规则校验
        merchantId: [
          {
            required: true,
            message: "请选择回收商家",
            trigger: "blur"
          }
        ],
        num: [
          {
            required: true,
            message: "请输入下账次数",
            trigger: "blur",
          },
          {
            pattern: /^0$|^[1-9]\d*$/,
            message: "只能输入大于等于0的正整数",
            trigger: "blur"
          }
        ],
        cause: [
          {
            required: true,
            message: "请输入原因说明",
            trigger: "blur"
          }
        ],
      },
      // 下账弹框
      downDialogVisible: false,
      command: '',
      // ***********
      type: this.$route.query.type,
      formProps,
      formProps2,
      formData: {},
      formRules: newFormRules,
      isBtnLoading: false,
      existImgList: [],
    }
  },
  components: { GlobalUploadGroup, GlobalChunk },
  created() {
    // 回收商下拉
    this.getStoreData();
    // 套餐下拉
    this.getPackageData();
  },

  watch: {
    // 回收商变化，请求对应联系人下拉
    'formData.merchantId'(v) {
      this.getContactsInfo(v);
    },

    // 联系人变化，设置手机号
    'formData.contacts'(v) {
      const index = this.formProps.findIndex(v => v.key === 'contacts');
      const valueIndex = this.formProps[index].option.findIndex(item => item.value === v);
      if (valueIndex < 0) {
        return this.formData.contactsPhone = "";
      }
      this.formData.contactsPhone = this.formProps[index].option[valueIndex].staffPhone ?? '';
      this.formData.recycleStaffId = this.formProps[index].option[valueIndex].staffId ?? '';
    },
    existImgList(v) {
      this.formData.voucher = v;
    },
  },

  methods: {
    merchantIdChange(e) {
      this.merchantId = e
      // 计算金额
      // if (this.formData2.num !==null){
      _api.merchantCalc({ merchantId: e, num: Number(this.formData2.num) || null }).then(res => {
        if (res.code === 1) {
          this.merchantCalc = res.data
          this.showFlag = true
        }
      })
      // }
    },
    numBlur() {
      // 计算金额
      if (this.merchantId) {
        _api.merchantCalc({ merchantId: this.merchantId, num: Number(this.formData2.num) || null }).then(res => {
          if (res.code === 1) {
            this.merchantCalc = res.data
            this.showFlag = true
          }
        })
      }
    },
    takeDeliveryCountBlur() {
      // 计算金额
      _api.calcRecharge({ num: Number(this.formData.takeDeliveryCount) || null }).then(res => {
        if (res.code === 1) {
          this.calcRecharge = res.data
          this.showFlag = true
        }
      })
    },
    // 回收商下拉
    getStoreData() {
      const params = {
        pageNum: 0,
        pageSize: 9999,
      }
      _api.listSimpleMerchantView(params).then(r => {
        const index = this.formProps.findIndex(v => v.key === 'merchantId');
        this.formProps[index].option = (r.data ?? []).map(v => {
          return {
            value: v.merchantId,
            label: v.merchantName
          }
        })
        // 下账
        // const index2 = this.formProps2.findIndex(v => v.key === 'merchantId');
        this.merchantOption = (r.data ?? []).map(v => {
          return {
            value: v.merchantId,
            label: v.merchantName
          }
        })
      })
    },
    // 联系人信息
    getContactsInfo(v) {
      const index = this.formProps.findIndex(v => v.key === 'contacts');
      this.formData = {
        ...this.formData,
        contacts: null,
        contactsPhone: null,
        recycleStaffId: null
      }
      if (!v && v !== 0) {
        return;
      }
      const params = {
        merchantId: v,
      }
      _api.recycleStaffSelect(params).then(r => {
        this.contactList = (r.data ?? []).map(v => {
          return {
            value: v.staffName,
            label: v.staffName,
            staffPhone: v.staffPhone,
            staffId: v.staffId
          }
        })
        this.formProps[index].option = (r.data ?? []).map(v => {
          return {
            value: v.staffName,
            label: v.staffName,
            staffPhone: v.staffPhone,
            staffId: v.staffId
          }
        })
      })
    },

    // 套餐下拉
    getPackageData() {
      _api.getRechargeConfigList().then(r => {
        this.rechargeConfigList = (r.data ?? []).map(v => {
          return {
            label: `${v.price}元（到账${v.arrivalTimes}次，赠送${v.giftTimes}次）`,
            value: v.id
          }
        })
        const index = this.formProps.findIndex(v => v.key === 'rechargeConfigId');
        this.formProps[index].option = (r.data ?? []).map(v => {
          return {
            label: `${v.price}元（到账${v.arrivalTimes}次，赠送${v.giftTimes}次）`,
            value: v.id
          }
        })
      })
    },
    submit() {
      this.$refs['formEle'].validate(v => {
        if (v) {
          this.isBtnLoading = true;
          // 直接赋值时this.formData.voucher，又多次点击时，存在问题
          const voucher = this.formData.voucher.map(v => v.imgUrl);
          _api.rechargeFrequency({ ...this.formData, voucher }).then(r => {
            this.isBtnLoading = false;
            this.$message.success("操作成功");
            // this.$router.back();
            this.$router.push({ path: "/payment/BillList", query: { type: this.type } })
          }).finally(() => {
            return this.isBtnLoading = false;
          })
        }
        return false
      })

    },
    // 下账
    submit2() {
      this.$refs['formEle2'].validate(v => {
        if (v) {
          this.downDialogVisible = true
        }
        return false
      })
    },
    // 动态口令弹框关闭
    downClosed() {
      this.command = ''
      this.downDialogVisible = false
    },
    downSubmit() {
      if (!this.downCommand) {
        return this.$message.error("请输入动态口令");
      }
      this.isBtnLoading = true;
      _api.minusFrequency({ command: this.downCommand, ...this.formData2 }).then(r => {
        if (r.code === 1) {
          this.$message.success("操作成功");
          this.$router.push({ path: "/payment/BillList", query: { type: this.type } })
          this.downDialogVisible = false
        }
      }).finally(() => {
        return this.isBtnLoading = false;
      })
    },
    goBack() { this.$router.push({ path: "/payment/BillList", query: { type: this.type } }) },
  }
}
</script>
<style scoped lang="scss">
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  /deep/ .el-input {
    width: 300px;
  }
}

.box {
  width: 100%;
  min-height: 80vh;
  background: #fff;
}
</style>
