export const formProps = [
    {
        labelName: "回收商家",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请输入或选择回收商家",
        clear: true,
        option: [
        ],
        clearFun: () => false
    },
    {
        labelName: "联系人",
        key: 'contacts',
        type: "selectFilterable",
        placeholder: "请输入或选择联系人姓名",
        clear: true,
        option: [
        ],
        clearFun: () => false
    },
    {
        labelName: "联系电话",
        key: "contactsPhone",
        type: "input",
        placeholder: "请输入联系电话"
    },
    {
        labelName: "充值金额",
        key: "rechargeConfigId",
        type: "selectFilterable",
        placeholder: "请选择充值金额",
        clear: true,
        option: [
        ],
        clearFun: () => false
    },
    {
        key: "payeeWay",
        type: "select",
        labelName: "支付方式",
        placeholder: "请选择收款方式",
        option: [
            {
                value: '私卡转账',
                label: "私卡转账",
            },
            {
                value: '授权调账',
                label: "授权调账",
            },
        ],
    },
    {
        labelName: "收款凭证",
        key: "voucher",
        prop: {
            placeTxt: "请上传本次的收款凭证(限3张内，大小不超过3M，格式为JPG或PNG)",
            length: 3,
            fileSize: 3,
            isBlank: false
        }
    },
    // 备注
    {
        labelName: "充值备注",
        key: "note",
        type:"textarea",
        placeholder: "请输入备注内容，最多100字",
        length:100,
        width:"50%"
    },
    // 提交
    {
        type: "btn"
    }
]
// 下账
export const formProps2 = [
    {
        labelName: "回收商家",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请输入或选择回收商家",
        clear: true,
        option: [
        ],
        clearFun: () => false
    },
    // 提交
    {
        type: "tips"
    },
    {
        labelName: "下账次数",
        key: "downNum",
        type: "number",
        placeholder: "请输入下账次数",
        clear: true,
        clearFun: () => false
    },
    {
        type: "tips2"
    },
    // 备注
    {
        labelName: "下账原因",
        key: "reason",
        type:"textarea",
        placeholder: "请输入原因说明(限200字)",
        length:200,
        width:"100%",
    },
    // 提交
    {
        type: "btn"
    }
]


export const formRules = {
    merchantId:[
        {
            required:true,
            message:"请选择回收商家",
            trigger:"blur"
        }
    ],
    downNum:[
        {
            required:true,
            message:"请输入下账次数",
            trigger:"blur",
        },
        {
            pattern: /^0$|^[1-9]\d*$/,
            message: "只能输入大于等于0的正整数",
            trigger: "blur"
        }
    ],
    reason:[
        {
            required:true,
            message:"请输入原因说明",
            trigger:"blur"
        }
    ],
    contacts:[
        {
            required:true,
            message:"请选择联系人",
            trigger:"blur"
        },
    ],
    contactsPhone:[
        {
            required:true,
            message:"请输入联系电话",
            trigger:"blur"
        }
    ],
    rechargeType:[
        {
            required:true,
            message:"请选择充值方式",
            trigger:"change"
        }
    ],
    takeDeliveryCount:[
        {
            required:true,
            message:"请输入充值收货次数",
            trigger:"change"
        }
    ],
    rechargeConfigId:[
        {
            required:true,
            message:"请选择充值金额",
            trigger:"blur"
        }
    ],
    payeeWay:[
        {
            required:true,
            message:"请选择支付方式",
            trigger:"blur"
        }
    ],
}
